.separator{
  margin-top: 40px;
  margin-bottom: 40px;
}

.book-us{
  background: #C68A61;
  padding: 8px 12px;
  border-radius: 10px;
  font-weight: 800;
  color: #ffffff;
}

.skills{
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  gap: 5px;

  li{
    list-style: none;
    background: #FFDA06;
    padding: 1.5rem 2rem;
    min-width: 150px;
    text-align: center;
    transition: all 0.4s ease-in-out;

    img{
      width: 40px;
      display: block;
      margin: auto;
      transition: all 0.5s ease-in-out;

      &:hover{
        width: 45px;
      }
    }
  }
}

.accueil{
  display: flex;
  align-items: center;
  gap: 4rem;
}

.droite{
  flex: 1;
}

@media only screen and (max-width: 991px), only screen and (max-device-width: 991px) {
  .accueil{
    flex-direction: column;
  }

  .our-awards{
    padding: 50px 0;
  }
}

//gallery

.masonry-grid {
  page-break-inside: avoid;
  break-inside: avoid;
  column-count: 2;
  column-gap: 15px;

  &-item {
    margin-bottom: 15px;
    position: relative;
    break-inside: avoid;
    width: 100%;
    will-change: transform;

    border-radius: 4px;
    overflow: hidden;
  }
}
@media screen and (min-width: 576px) {
  .masonry-grid {
    column-count: 3;
  }
}

@media screen and (min-width: 768px) {
  .masonry-grid {
    column-count: 5;
  }
}

@media screen and (min-width: 992px) {
  .masonry-grid {
    column-count: 6;
  }
}

//values

.values-container{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

//about

.about-content{
  margin-bottom: 5rem;
}